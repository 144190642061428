import { useSelector } from 'react-redux';
import { modelDataSelector } from '../../store/selectors/general';

export const ModelsPerPageSelector = ({ modelsPerPage, setModelsPerPage }) => {
  const allModelsData = useSelector(modelDataSelector);

  return (
    <div className="flex flex-col gap-1">
      <label>Number of models shown</label>
      <select
        id="debtType"
        className="w-52 rounded-[10px] focus:rounded-[20px]"
        value={modelsPerPage}
        onChange={e => setModelsPerPage(e.target.value)}
      >
        <option key="models-" value="" label="Select.." disabled />
        <option key="models-10" value={10} label="10" />
        <option key="models-20" value={20} label="20" />
        <option key="models-all" value={allModelsData.length} label="All models" />
      </select>
    </div>
  );
};
