import { useEffect, useState } from 'react';
import { Filters } from './Filters';
import { CatalogTable } from './CatalogTable';
import { PageNavigation } from './PageNavigation';
import { useSelector } from 'react-redux';
import { modelDataSelector } from '../store/selectors/general';
import { SectionLoader } from './general/Loaders';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { ModelsPerPageSelector } from './general/ModelsPerPageSelector';

import Button from './general/Button';
import idrtLogo from '../assets/idrt-logo.png';
import gloLogo from '../assets/glo-logo.svg';
import tdisLogo from '../assets/tdis-logo.png';

const MODEL_TYPES = ['hydrodynamic', 'hydrologic', 'hydraulic', 'nonPhysics'];
const MODEL_PROCESSES = ['coastal', 'pluvial', 'fluvial'];
const MODEL_SCALES = ['local', 'watershed', 'regional', 'continental'];
const MODEL_PHASES = ['preparedness', 'mitigation', 'response', 'recovery'];

const FILTERS_MATCHING = {
  hydrodynamic: 'Hydrodynamic',
  hydrologic: 'Hydrologic',
  hydraulic: 'Hydraulic',
  nonPhysics: 'Simplified/Non-Physics',
  coastal: 'Coastal',
  pluvial: 'Pluvial',
  fluvial: 'Fluvial',
  local: 'Site-scale/Local',
  watershed: 'Watershed',
  regional: 'Multi-washed/Regional',
  continental: 'Continental',
  preparedness: 'Preparedness',
  mitigation: 'Mitigation',
  response: 'Response',
  recovery: 'Recovery',
};

const DEFAULT_NUMBER = 10;

const Main = () => {
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState({});
  const [selectedModelsData, setSelectedModelsData] = useState(null);
  const [modelsPerPage, setModelsPerPage] = useState(DEFAULT_NUMBER);

  const allModelsData = useSelector(modelDataSelector);

  useEffect(() => {
    if (allModelsData) {
      let selectedModels = allModelsData;

      // Filtering all the models by the search params passed (case insensitive)
      if (search) {
        const regexMatch = new RegExp(search, 'i');
        selectedModels = selectedModels.filter(
          model => model['Model Name'].match(regexMatch) || model['Developer'].match(regexMatch),
        );
      }

      // Applying the selected filters to the models data
      if (filters.length > 0) {
        // Creates a clone of the filters array
        let cloneFilters = filters.slice();

        // First, if the NFIP filter has been selected, exclude all the models that are not NFIP-approved (exclusive filtering)
        // Removes the nfipApproved filter from the clone array
        if (cloneFilters.includes('nfipApproved')) {
          selectedModels = selectedModels.filter(model => model['NFIP Approved'] === 'Yes');
          cloneFilters.splice(cloneFilters.indexOf('nfipApproved'), 1);
        }

        // Helper function that checks if the model meets the filters applied by the user
        const filtersFunc = model => {
          const filtersCheck = cloneFilters.map(f => {
            if (MODEL_TYPES.includes(f)) {
              return model['Model Type'] === FILTERS_MATCHING[f];
            } else if (MODEL_PROCESSES.includes(f)) {
              return model['Process'].includes(FILTERS_MATCHING[f]);
            } else if (MODEL_SCALES.includes(f)) {
              return model['Scale'].includes(FILTERS_MATCHING[f]);
            } else if (MODEL_PHASES.includes(f)) {
              return model['Phase'].includes(FILTERS_MATCHING[f]);
            } else {
              return false;
            }
          });
          return filtersCheck.some(value => value === true);
        };

        // Finally, if there's more filters apart from NIP approved, filter by type, process, scale and phase (inclusive filtering)
        if (cloneFilters.length > 0) {
          selectedModels = selectedModels.filter(model => filtersFunc(model));
        }
      }

      const startIndex = modelsPerPage * (pageNum - 1);
      const endIndex = modelsPerPage * pageNum;
      setSelectedModelsData(selectedModels.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(selectedModels?.length / modelsPerPage));
    }
  }, [allModelsData, pageNum, search, filters, modelsPerPage]);

  // Downloads the model method catalog in CSV and closes the filters menu
  const downloadCSV = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = `${process.env.PUBLIC_URL}/data/models-data.csv`;
    downloadLink.download = 'models-data.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-center gap-10 h-96 bg-cover-image bg-no-repeat bg-cover backdrop-filter backdrop-blur">
        <h1 className="flex text-8xl text-white">M3FR</h1>
        <h2 className="uppercase w-1/4 text-2xl text-white hidden md:flex">
          Measuring, Mapping and Managing Flood Risk
        </h2>
      </div>

      <div className="flex flex-col md:flex-row justify-around items-center min-h-72 w-full bg-primary">
        <div className="flex flex-col items-center gap-6 my-10">
          <a href="https://idrt.tamug.edu/" target="_blank" rel="noreferrer">
            <img src={idrtLogo} alt="IDRT Logo" className="h-24 w-64 md:h-28 md:w-80" />
          </a>
          <a href="https://glo.texas.gov/" target="_blank" rel="noreferrer">
            <img src={gloLogo} alt="GLO Logo" className="h-28 w-28 md:w-28 md:h-28" />
          </a>
          <a href="https://idrt.tamug.edu/tdis/" target="_blank" rel="noreferrer">
            <img src={tdisLogo} alt="TDIS Logo" className="h-24 w-64 md:h-28 md:w-72" />
          </a>
        </div>

        <div className="mx-10 text-xl *:text-white mb-10 md:text-justify md:mb-0 leading-loose md:w-1/2 md:mx-0 md:py-10">
          <p>
            Measuring, Mapping, and Managing Flood Risk in Texas (M3FR) is a project funded by The
            Texas General Land Office (GLO) and led by the Texas A&M University's Institute for a
            Disaster Resilient Texas (IDRT). The project aims to improve flood risk communication by
            integrating multiple types of flood risk and hazard models and leveraging risk
            communication best practices.
          </p>
          <p className="mt-8">Key points:</p>
          <ul className="ml-4">
            <li>
              In its initial year of funding, M3FR created a comprehensive catalog of commonly used
              flood hazard models in the United States.
            </li>
            <li>
              Flood models are essential for decision-makers, offering insights into flood hazards
              crucial for planning, response, and recovery.
            </li>
            <li>
              This catalog highlights key characteristics of each model that are relevant to the
              needs of decision makers, emphasizing practical utility over academic details to serve
              as effective guidelines for disaster management and flood-related issues.
            </li>
          </ul>
        </div>
      </div>

      <div className="m-8 md:m-16">
        {!selectedModelsData ? (
          <div className="flex flex-col items-center">
            <SectionLoader />
            <p className="mt-4">Loading models...</p>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-6 md:flex-row md:gap-0 md:justify-between md:items-center">
              <h1>Model Method Catalog</h1>
              <Filters setSearch={setSearch} setFilters={setFilters} />
            </div>
            <div className="flex flex-col-reverse items-start gap-8 md:flex-row md:justify-between md:items-center md:gap-10 mt-8 z-0">
              <ModelsPerPageSelector
                modelsPerPage={modelsPerPage}
                setModelsPerPage={setModelsPerPage}
              />

              <Button className="link-button flex gap-2 mt-0 md:mt-6" onClick={downloadCSV}>
                <ArrowDownTrayIcon className="w-6" />
                Export Catalog Selection (csv)
              </Button>
            </div>
            <CatalogTable data={selectedModelsData} />
            <PageNavigation pageNum={pageNum} setPageNum={setPageNum} totalPages={totalPages} />
          </>
        )}

        <div className="my-8 *:mb-4">
          <h1>Find existing flood models in your area</h1>
          <h2>Coming soon!</h2>
        </div>
      </div>
    </div>
  );
};

export default Main;
