import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_ROOT = process.env.REACT_APP_API_ROOT || '/api';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: build => ({
    sendEmail: build.mutation({
      query: body => ({
        url: '/emails/send-email',
        method: 'POST',
        body,
      }),
    }),
  }),
});

// These export hooks are automatically defined by createApi, they
// can be imported in functional components to fetch data, see fetch
// status and get the data from the redux store.
export const { useSendEmailMutation } = api;
