export const validateReportFeedback = values => {
  const errors = {};
  if (values.feedbackType.additions && !values.additionsFeedback) {
    errors.additionsFeedback = 'Please provide feedback or uncheck this option above';
  }
  if (values.feedbackType.mistakes && !values.mistakesFeedback) {
    errors.mistakesFeedback = 'Please provide feedback or uncheck this option above';
  }
  if (values.feedbackType.suggestions && !values.suggestionsFeedback) {
    errors.suggestionsFeedback = 'Please provide feedback or uncheck this option above';
  }
  if (values.feedbackType.errors && !values.errorsFeedback) {
    errors.errorsFeedback = 'Please provide feedback or uncheck this option above';
  }
  if (!values.userEmail) {
    errors.userEmail = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userEmail)) {
    errors.userEmail = 'Invalid email address';
  }
  return errors;
};
