import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modelDataSelector } from '../store/selectors/general';

const Methodology = () => {
  const allModelsData = useSelector(modelDataSelector);
  const [allModelsAlphabetically, setAllModelsAlphabetically] = useState(null);

  const glossaryHeaders = 'text-xl uppercase';

  useEffect(() => {
    if (allModelsData) {
      const modelsAlphabetically = allModelsData.sort((modelA, modelB) =>
        modelA['Model Name'] < modelB['Model Name']
          ? -1
          : modelA['Model Name'] > modelB['Model Name']
            ? 1
            : 0,
      );
      setAllModelsAlphabetically(modelsAlphabetically);
    }
  }, [allModelsData]);

  useEffect(() => {
    setTimeout(() => {
      const id = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, []);

  return (
    <div className="w-full min-h-screen">
      <main className="m-8 md:m-16 [&_p]:text-lg md:[&_p]:text-justify [&_p]:my-6 [&_ol]:text-lg [&_ul]:text-lg">
        <h1>Methodology</h1>

        <p>
          We compile a list of the most widely used flood hazard models in the United States in a
          model catalog. Each model is tagged with several attributes (categorical and descriptive)
          that provides useful model-related information for the intended users. As mentioned
          previously, the model catalog is not meant to be exhaustive, but is instead intended to
          serve as a reference tool to aid in decision making.
        </p>

        <p>Model listed in the catalog must meet at least three of the following criteria:</p>
        <ol className="ml-4 my-6">
          <li>meets the minimum requirements of the NFIP (FEMA Approved)</li>
          <li>is being actively maintained or studied</li>
          <li>
            produces flood risk, hazard, and other relevant flood information such as: depth;
            damage; discharge; duration; velocity; extent; and likelihood of flooding
          </li>
          <li>is being actively used within the State of Texas</li>
        </ol>

        <h1>Glossary</h1>

        <p id="model-name" className="!mb-4">
          <b className={glossaryHeaders}>Model name</b> - Name of the model/tool.
        </p>
        <ul className="ml-6">
          {!allModelsAlphabetically ? (
            <p>Loading models info...</p>
          ) : (
            allModelsAlphabetically.map(model => (
              <li key={`model-${model['Number']}`}>
                <b>{model['Model Name']}</b> - {model['Description']}
              </li>
            ))
          )}
        </ul>

        <p id="model-type" className="!mb-4">
          <b className={glossaryHeaders}>Model type</b> - Categorizes the model by its
          type/function:
        </p>
        <ul className="ml-6">
          <li>
            <b>Physics-based models</b> - Use mathematical equations and algorithms to simulate how
            water flows, accumulates, and interacts with the landscape during flooding events:
          </li>
          <ol className="ml-10 mb-4 [&_li::marker]:font-bold [&_li]:mb-4">
            <li id="hydrologic">
              <b>Hydrologic</b> - Hydrologic models simulate the various processes of the hydrologic
              cycle, including rainfall, surface runoff (discharge), losses (e.g., infiltration,
              evaporation, transpiration, interception), and flood storage, either natural
              (wetlands, lakes) or manmade (detention and retention ponds, dams, reservoirs).
              Important outputs generated by hydrologic models are discharge and runoff volume of
              specific locations or areas of interest, usually represented by flow hydrographs
              (plots of flow vs time). Hydrologic models are commonly used in watershed studies for
              event-based storm scenarios (both hypothetical and actual/historical) as well as long
              term/continuous scenarios (e.g., seasonal/annual assessment).
            </li>
            <li id="hydraulic">
              <b>Hydraulic</b> - Hydraulic models simulate the flow, conveyance, and routing of
              fluid in both open-channels (e.g., rivers and their tributaries) and closed systems
              (e.g., pipe network). Important outputs generated by hydraulic models are flow, water
              elevation, and velocities in water bodies, represented by flow hydrographs, stage
              hydrographs (plots of water elevation over time), and velocity profiles, respectively.
              Applications of hydraulic models include generating floodplain maps (e.g., inundation
              boundary and hotspots, depth grid, etc.), conducting a flood impact assessment (e.g.,
              dam breach, bridge scour analysis), and probabilistic flood frequency analyses.
            </li>
            <li id="hydrodynamic">
              <b>Hydrodynamic</b> - Hydrodynamic models have the capability of simulating both the
              hydrologic and hydraulic processes of an area of interest, which means they can
              simulate the rainfall-runoff processes as well as the flood routing within water
              bodies. Hydrodynamic models can be categorized by their flow direction into
              one-dimensional (1D), two-dimensional (2D), and three-dimensional (3D) models. 1D
              hydrodynamic models represent flow only in one direction (upstream / downstream), 2D
              models in 2 directions (horizontal / lateral flow), and 3D models in 3 directions
              (horizontal / lateral and vertical flows). In the context of flood risk and flood
              hazards, most commonly used hydrodynamic models are 1D, 2D, and hybrid 1D/2D versions.
            </li>
          </ol>
          <li id="simplified">
            <b>Non-physics-based models</b> - Include any types of models not driven/governed by
            physical processes. These include simplified physics-based models, geographic
            information system (GIS) approaches, statistical/data-driven methods, and various
            artificial intelligence (AI)/machine learning (ML)-based models.
          </li>
        </ul>

        <p id="model-process" className="!mb-4">
          <b className={glossaryHeaders}>Model process</b> - Categorizes the model by the process it
          simulates:
        </p>
        <ul className="ml-6">
          <li id="fluvial">
            <b>Fluvial</b> - Fluvial models simulate fluvial/floodplain processes including riverine
            flooding, which is caused by water overtopping channel banks. These models are designed
            to assess the potential extent, depth, and duration of riverine flooding under various
            hydrological conditions. Models that simulate fluvial processes are typically capable of
            representing natural and manmade rivers/channels and related hydraulic infrastructure
            (e.g., dams, weirs, levees, bridges).
          </li>
          <li id="pluvial">
            <b>Pluvial</b> - Pluvial flood models are used to simulate and predict flooding caused
            by heavy rainfall events, particularly in urban and suburban areas. Pluvial flooding
            occurs when intense rainfall overwhelms the capacity of drainage systems, resulting in
            localized inundation of streets, neighborhoods, and other low-lying areas. Pluvial
            models are capable of simulating overland flows in open-channel water conveyance systems
            (e.g., roadways and ditches), and some are capable of also simulating flows in closed
            systems (e.g., subsurface storm sewer network).
          </li>
          <li id="coastal">
            <b>Coastal</b> - Coastal models simulate coastal water dynamics, including flooding
            drivers such as storm surge and waves. Some coastal models can also simulate the
            evolution of long-term factors in flood risk, such as coastal erosion and sea level
            rise. Coastal flood models typically incorporate a wide range of factors including
            meteorological conditions (e.g., wind speed and direction), oceanographic conditions
            (e.g., sea level variations and wave characteristics), coastal morphology (shoreline
            shape and bathymetry), land use characteristics, and the presence of protective
            structures (e.g., seawalls or levees).
          </li>
          <li id="compound">
            <b>Compound</b> - Compound models can simulate the combined impacts of multiple hazards,
            such as the flooding that would result from interactions between inland (fluvial and
            pluvial) and coastal flooding that occurred simultaneously. These models integrate
            various factors such as precipitation, river discharge, tides, and storm surge to
            provide a comprehensive understanding of the potential flooding scenarios. By
            considering the combined effects of multiple hazards, compound flood models can provide
            more accurate assessments of flood risk and flood hazard, improve early warning systems,
            and inform decision-making processes related to land use planning, infrastructure
            development, and disaster response strategies in coastal areas.
          </li>
        </ul>

        <p id="scale" className="!mb-2">
          <b className={glossaryHeaders}>Scale</b> - Categorizes the model by the relative
          size/areal extent of the study area:
        </p>
        <ul className="ml-6">
          <li id="site-scale">
            <b>Site-scale/local</b> - neighborhood/subdivision, areas less than 1 mi<sup>2</sup>.
          </li>
          <li id="watershed">
            <b>Watershed</b> - HUC-10, HUC-12 and smaller.
          </li>
          <li id="multi-watershed">
            <b>Multi-watershed</b> - HUC-2 down to HUC-8 regions.
          </li>
          <li id="continental">
            <b>Continental</b> - North America; for coastal models, North Atlantic, North Pacific,
            or the Gulf of Mexico.
          </li>
        </ul>

        <p id="developer">
          <b className={glossaryHeaders}>Developer/Source</b> - Lists the developer of the model
          and/or the link/source to where the model is available.
        </p>

        <p id="scope">
          <b className={glossaryHeaders}>Scope</b> – Describes the scope of usage for the specified
          model.
        </p>

        <p id="availability" className="!mb-2">
          <b className={glossaryHeaders}>Availability</b> - Describes the availability of the model:
        </p>
        <ul className="ml-6">
          <li id="open-source">
            <b>Open source</b> - model is publicly available, including source code/API.
          </li>
          <li id="open-access">
            <b>Open access</b> - model is publicly available, but limited/no access to source
            code/API.
          </li>
          <li id="limited-access">
            <b>Limited access</b> - access to model and its source code/API is limited, e.g.,
            requires permission.
          </li>
          <li id="proprietary">
            <b>Proprietary</b> - access to model is limited, e.g., requires license, restricted/no
            access to source code/API.
          </li>
        </ul>

        <p id="nfip-approval">
          <b className={glossaryHeaders}>NFIP approval</b> - Notes whether the model is listed by
          FEMA as meeting the minimum requirement for the NFIP:{' '}
          <a
            href="https://www.fema.gov/flood-maps/products-tools/numerical-models"
            target="_blank"
            rel="noreferrer"
            className="text-hyperlink font-light"
          >
            https://www.fema.gov/flood-maps/products-tools/numerical-models
          </a>
        </p>

        <p id="phase" className="!mb-2">
          <b className={glossaryHeaders}>Disaster management phase</b> - Describes the phase(s) of
          disaster management where the model is most applicable:
        </p>
        <ul className="ml-6">
          <li id="mitigation">
            <b>Mitigation</b> - pre-disaster, e.g., flood mitigation feasibility studies.
          </li>
          <li id="preparedness">
            <b>Preparedness</b> - pre-disaster, e.g., inundation hotspot analysis, scenario
            analysis.
          </li>
          <li id="response">
            <b>Response</b> - during and post-disaster, e.g., real-time flood risk/hazard
            estimation.
          </li>
          <li id="recovery">
            <b>Recovery</b> - post-disaster, e.g., flood impact studies, damage analysis.
          </li>
        </ul>

        <p id="outputs">
          <b className={glossaryHeaders}>Model outputs</b> - Lists model outputs/products related to
          flood risk and flood hazard. Examples include flow/discharge, flood depth, water
          level/stage, flood storage/volume, flood velocity, inundation extent, flood duration, etc.
        </p>

        <p id="case-studies">
          <b className={glossaryHeaders}>Case studies</b> - Lists case studies/examples of where the
          model has been used.
        </p>

        <p id="dashboard">
          <b className={glossaryHeaders}>Dashboard application</b> - Lists tools, web applications,
          and dashboards where the model has been used.
        </p>

        <p id="strengths-and-weaknesses">
          <b className={glossaryHeaders}>Strengths and weaknesses</b> - Lists notable strengths and
          weaknesses of the model.
        </p>

        <p id="recommendations">
          <b className={glossaryHeaders}>Recommendations</b> - Documents specific use case/function
          of the listed model, if applicable.
        </p>
      </main>
    </div>
  );
};

export default Methodology;
