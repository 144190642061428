import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { validateReportFeedback } from '../validators';
import Button from './general/Button';
import {
  MODELS_ADDITIONS,
  MODELS_MISTAKES,
  USABILITY_SUGGESTIONS,
  APP_ERRORS,
  GENERAL_FEEDBACK,
} from '../constants/general';
import { useSendEmailMutation } from '../services/api';
import { errorToast, successToast } from '../utils/toast';

const ReportFeedback = () => {
  const navigate = useNavigate();
  const [sendFeedbackFormEmailTrigger, { isLoading: isSubmitting }] = useSendEmailMutation();

  const emailFeedback = values => {
    const additions = values.additionsFeedback
      ? { label: MODELS_ADDITIONS, feedback: values.additionsFeedback }
      : null;
    const mistakes = values.mistakesFeedback
      ? { label: MODELS_MISTAKES, feedback: values.mistakesFeedback }
      : null;
    const suggestions = values.suggestionsFeedback
      ? { label: USABILITY_SUGGESTIONS, feedback: values.suggestionsFeedback }
      : null;
    const errors = values.errorsFeedback
      ? { label: APP_ERRORS, feedback: values.errorsFeedback }
      : null;
    const other = values.otherFeedback
      ? { label: GENERAL_FEEDBACK, feedback: values.otherFeedback }
      : null;

    sendFeedbackFormEmailTrigger({
      userEmail: values.userEmail,
      additions,
      mistakes,
      suggestions,
      errors,
      other,
    })
      .unwrap()
      .then(_ => {
        successToast('Feedback form successfully sent!');
        navigate('/');
      })
      .catch(error => {
        console.error(error);
        errorToast('Unable to send the feedback form. Please try again later.');
      });
  };

  const formik = useFormik({
    initialValues: {
      feedbackType: {
        additions: false,
        mistakes: false,
        suggestions: false,
        errors: false,
      },
      additionsFeedback: '',
      mistakesFeedback: '',
      suggestionsFeedback: '',
      errorsFeedback: '',
      otherFeedback: '',
      userEmail: '',
    },
    validate: validateReportFeedback,
    onSubmit: emailFeedback,
  });

  return (
    <div className="w-full min-h-screen">
      <div className="h-96 bg-cover-image bg-no-repeat bg-cover">
        <div className="w-full h-full flex items-center justify-center gap-10 backdrop-brightness-50">
          <h1 className="flex text-8xl text-sage">
            M<span className="text-white">3</span>FR
          </h1>
          <h2 className="uppercase text-2xl text-white hidden md:flex">
            Measuring, Mapping and Managing Flood Risk
          </h2>
        </div>
      </div>
      <main className="flex flex-col justify-center items-center my-10">
        <h1 className="text-[40px] uppercase">
          M<span className="text-sage">3</span>FR Feedback Form
        </h1>
        <p className="mt-4 mb-10">
          Please leave a comment or suggestion on how to improve the flood model catalog and its
          usability. We appreciate your input!
        </p>

        <form onSubmit={formik.handleSubmit} className="flex flex-col items-center justify-center">
          <div role="group" aria-labelledby="feedback-type-checkbox-group" className="mb-10">
            <label className="font-bold">
              I would like to submit feedback regarding the following topics. Select all that apply:
            </label>

            <div className="ml-2">
              <div className="checkbox-style">
                <input
                  id="feedbackType.additions"
                  type="checkbox"
                  checked={formik.values.feedbackType.additions}
                  onChange={_ =>
                    formik.setFieldValue(
                      'feedbackType.additions',
                      !formik.values.feedbackType.additions,
                    )
                  }
                />
                <label htmlFor="feedbackType.additions">
                  I’d like to suggest additional models for the catalog
                </label>
              </div>

              <div className="checkbox-style">
                <input
                  id="feedbackType.mistakes"
                  type="checkbox"
                  checked={formik.values.feedbackType.mistakes}
                  onChange={_ =>
                    formik.setFieldValue(
                      'feedbackType.mistakes',
                      !formik.values.feedbackType.mistakes,
                    )
                  }
                />
                <label htmlFor="feedbackType.mistakes">
                  I’d like to report a mistake I found within the catalog’s content
                </label>
              </div>

              <div className="checkbox-style">
                <input
                  id="feedbackType.suggestions"
                  type="checkbox"
                  checked={formik.values.feedbackType.suggestions}
                  onChange={_ =>
                    formik.setFieldValue(
                      'feedbackType.suggestions',
                      !formik.values.feedbackType.suggestions,
                    )
                  }
                />
                <label htmlFor="feedbackType.suggestions">
                  I’d like to offer suggestions to improve usability
                </label>
              </div>

              <div className="checkbox-style">
                <input
                  id="feedbackType.errors"
                  type="checkbox"
                  checked={formik.values.feedbackType.errors}
                  onChange={_ =>
                    formik.setFieldValue('feedbackType.errors', !formik.values.feedbackType.errors)
                  }
                />
                <label htmlFor="feedbackType.errors">
                  I'd like to report bug(s) or error(s) that I encountered while browsing the site
                </label>
              </div>
            </div>
          </div>

          {formik.values.feedbackType.additions && (
            <div className="field-style !mt-0 w-[650px] [&_label]:font-bold">
              <label htmlFor="additionsFeedback">{MODELS_ADDITIONS}</label>
              <textarea
                id="additionsFeedback"
                placeholder="TYPE YOUR ANSWER HERE"
                rows="5"
                cols="33"
                className="rounded-[10px] border-dark_blue focus:rounded-[20px]"
                value={formik.values.additionsFeedback}
                onChange={formik.handleChange}
              />
              {formik.touched.additionsFeedback && formik.errors.additionsFeedback ? (
                <p className="formik-error">{formik.errors.additionsFeedback}</p>
              ) : null}
            </div>
          )}

          {formik.values.feedbackType.mistakes && (
            <div className="field-style !mt-0 w-[650px] [&_label]:font-bold">
              <label htmlFor="mistakesFeedback">{MODELS_MISTAKES}</label>
              <textarea
                id="mistakesFeedback"
                placeholder="TYPE YOUR ANSWER HERE"
                rows="5"
                cols="33"
                className="rounded-[10px] border-dark_blue focus:rounded-[20px]"
                value={formik.values.mistakesFeedback}
                onChange={formik.handleChange}
              />
              {formik.touched.mistakesFeedback && formik.errors.mistakesFeedback ? (
                <p className="formik-error">{formik.errors.mistakesFeedback}</p>
              ) : null}
            </div>
          )}

          {formik.values.feedbackType.suggestions && (
            <div className="field-style !mt-0 w-[650px] [&_label]:font-bold">
              <label htmlFor="suggestionsFeedback">{USABILITY_SUGGESTIONS}</label>
              <textarea
                id="suggestionsFeedback"
                placeholder="TYPE YOUR ANSWER HERE"
                rows="5"
                cols="33"
                className="rounded-[10px] border-dark_blue focus:rounded-[20px]"
                value={formik.values.suggestionsFeedback}
                onChange={formik.handleChange}
              />
              {formik.touched.suggestionsFeedback && formik.errors.suggestionsFeedback ? (
                <p className="formik-error">{formik.errors.suggestionsFeedback}</p>
              ) : null}
            </div>
          )}

          {formik.values.feedbackType.errors && (
            <div className="field-style !mt-0 w-[650px] [&_label]:font-bold">
              <label htmlFor="errorsFeedback">{APP_ERRORS}</label>
              <textarea
                id="errorsFeedback"
                placeholder="TYPE YOUR ANSWER HERE"
                rows="5"
                cols="33"
                className="rounded-[10px] border-dark_blue focus:rounded-[20px]"
                value={formik.values.errorsFeedback}
                onChange={formik.handleChange}
              />
              {formik.touched.errorsFeedback && formik.errors.errorsFeedback ? (
                <p className="formik-error">{formik.errors.errorsFeedback}</p>
              ) : null}
            </div>
          )}

          <div className="field-style !mt-0 w-[650px] [&_label]:font-bold">
            <label htmlFor="otherFeedback">{GENERAL_FEEDBACK}</label>
            <textarea
              id="otherFeedback"
              placeholder="TYPE YOUR ANSWER HERE"
              rows="5"
              cols="33"
              className="rounded-[10px] border-dark_blue focus:rounded-[20px]"
              value={formik.values.otherFeedback}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field-style w-[650px] !mb-16">
            <label htmlFor="userEmail" className="font-bold">
              Please provide your email address:*
            </label>
            <label>*We will only use this information to contact you regarding your feedback</label>
            <input
              id="userEmail"
              type="email"
              placeholder="Email Address"
              value={formik.values.userEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.userEmail && formik.errors.userEmail ? (
              <p className="formik-error">{formik.errors.userEmail}</p>
            ) : null}
          </div>

          <Button type="submit" isLoading={isSubmitting}>
            SUBMIT MY FORM
          </Button>
        </form>
      </main>
    </div>
  );
};

export default ReportFeedback;
